$hamburger-padding-x: 12px;
$hamburger-padding-y: 0px; // set in twig to accomm top/bottom padding
$hamburger-layer-width: 24px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #1F8252; // color.dragon-green
$hamburger-layer-border-radius: 8px;

$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 200ms;
$hamburger-hover-transition-timing-function: ease-in-out;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(100%);

$hamburger-active-layer-color: #ffffff;
$hamburger-active-hover-opacity: 1;
$hamburger-active-hover-filter: opacity(100%);

$hamburger-types: (elastic);
