// tailwind: font-bold
// design: "black"
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Bold.woff2') format('woff2'),
    url('../fonts/AvenirNext-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-BoldItalic.woff2') format('woff2'),
    url('../fonts/AvenirNext-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

// tailwind: font-semibold
// design: "heavy"
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-DemiBold.woff2') format('woff2'),
    url('../fonts/AvenirNext-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// tailwind: font-medium
// design: "medium"
@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
    url('../fonts/AvenirNext-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Regular.woff2') format('woff2'),
    url('../fonts/AvenirNext-Regular.woff') format('woff');
  font-weight: 400; // font-normal
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../fonts/AvenirNext-Italic.woff2') format('woff2'),
    url('../fonts/AvenirNext-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// Arial font
// tailwind: font-bold
// design: "CEBold"
@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-CEBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

// tailwind: font-semibold
// design: "Bold"
@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// tailwind: font-medium
// design: "medium"
@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Regular.woff') format('woff');
  font-weight: 400; // font-normal
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// Museo Sans font
// tailwind: font-regular
@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-300.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// tailwind: font-bold
@font-face {
  font-family: 'Museo Sans';
  src: url('../fonts/MuseoSans-700.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.unsubscribe-btn {
  position: relative;
  outline: none !important;
  width: 100%;
  height: 52px;
  padding: 16px 24px;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  font-size: 16px;
  &::before {
    content: '';
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-right-style: solid;
    border-right-width: 3px;
    position: absolute;
    top: 40%;
    height: 9px;
    width: 9px;
  }

  &.right-arrow {
    &::before {
      right: 30px;
      transform: rotate(315deg);
    }
  }
}
/* spinner.css */
/* Spinner Container */
.request-spinner-container {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner */
.request-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid rgb(31,130,82);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon-item {
  padding-left: 10px;
}
.social-icon {
  font-size: var(--icon-size, 22px);
  line-height: var(--icon-size, 22px);
  width: calc(var(--icon-size, 22px) + 2* var(--icon-padding, .5em));
  height: calc(var(--icon-size, 22px) + 2* var(--icon-padding, .5em));
  border-radius: 24px 24px 24px 24px;
  display: inline-flex;
  background-color: #69727d;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.icon-linkedin {
  background-color: #0077b5;
}

.fa-linkedin {
  &::before {
    content: "\f08c";
  }
}

.icon-facebook {
  background-color: #3b5998;
}

.fa-facebook {
  &::before {
    content: "\f09a";
  }
}

.fa-google {
  &::before {
    content: "\f1a0";
  }
}

.icon-seek {
  background-color: #0d3880;
}

.idom-hamburger {
  .hamburger-inner {
    background-color: #fff !important;
    &::before {
      background-color: #fff !important;
    }
    &::after {
      background-color: #fff !important;
    }
  }
}

.-icon-twitter {
  padding: 8px;
}

.idomi :where(a):not(:where([class~="not-prose"] *)) {
  color: #18A0FB !important;
}

.vwb :where(a):not(:where([class~="not-prose"] *)) {
  color: #1C9DD9 !important;
}

.vwb :where(a):not(:where([class~="not-prose-grey"] *)) {
  color: #1C9DD9 !important;
}

/* Styling for submenu */
.submenu {
  top: 100%;
  width: max-content;
  transition: opacity 0.2s ease-in-out;
}

.arrow {
  content: ' ';
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-color:  black;
  opacity: 0.75;
  border-right-width: 1px;
  height: 8px;
  width: 8px;
  margin-left: 0.25rem;
  
  &.arrow-bottom {
    margin-bottom: 2px;
    transform: rotate(45deg);
  }

  &.arrow-top {
    margin-bottom: -2px;
    transform: rotate(-135deg);
  }
}

.js-nav__menu {
  max-height: 100vh;
  overflow-y: auto;
}

@media screen and (max-width: 1024px) {
  .arrow {
    border-color: white;
    opacity: 1;
    height: 14px;
    width: 14px;
    &.arrow-bottom {
      margin-bottom: 4px;
    }
  }
  .submenu {
    width: unset;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .group:hover .submenu {
    display: block;
  }

  .group:hover .arrow {
    margin-bottom: -2px;
    transform: rotate(-135deg);
  }
}

/* Styling for VWB style */
.vwb-value-item {
  max-width: 18rem;

  &:first-child {
    @media screen and (min-width: 768px) {
      border-bottom-left-radius: 5rem;
    }
    @media screen and (max-width: 768px) {
      border-top-left-radius: 5rem;
    }
  }

  &:last-child {
    @media screen and (min-width: 768px) {
      border-top-right-radius: 5rem;
    }
    @media screen and (max-width: 768px) {
      border-bottom-right-radius: 5rem;
    }
  }
}

.vwb-form {
  border-top-left-radius: 3rem !important;

}

.vwb-form-div {
  padding-top: 10px;
}

.vwb-form-container {
  display: flex;
  max-width: 26rem;
  border-top-left-radius: 4rem;
  background-color: #1C9DD9;
  @media screen and (max-width: 768px) {
    max-width: 20rem;
    margin: 0 auto;
  }
}

.vwb-form-container::before {
  content: "";
  width: 28px;
}

.vwb-form-btn {
  border-bottom-right-radius: 2rem !important;
}

.vwb-btn {
  border-bottom-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.success-dialog {
  display:none; 
  position:fixed; 
  top:50%; 
  left:50%; 
  transform:translate(-50%, -50%); 
  padding:20px; 
  color: white; 
  border-radius: 5px;
  width: 280px;
}

.close-dialog-btn {
  margin: 0 auto;
  width: 100%;
  padding-top: 20px;
  background-color: #e5f7ff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .hero-image-1 {
    width: 600px;
  }
  .hero-image-2 {
    width: 581px;
  }
  .hero-image-text {
    margin-top: 15px;
    font-size: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .hero-image-1 {
    padding: 20px 30px 10px 30px;
  }
  .hero-image-2 {
    display: none;
  }
  .vwb-form-text {
    font-size: 25px !important;
    margin-top: 10px;
  }
  .hero-image-text {
    margin-top: -20px;
  }
}
// // ==========================================================================||
// //  Settings
// // ==========================================================================||

@import './settings/settings.hamburgers.scss';

// // ==========================================================================||
// //  Vendors
// // ==========================================================================||

@import 'hamburgers/_sass/hamburgers/base';
@import 'hamburgers/_sass/hamburgers/types/elastic';

@import 'swiper/scss';
@import 'swiper/scss/scrollbar';

@import 'plyr/src/sass/plyr.scss';

// // ==========================================================================||
// //  Base
// // ==========================================================================||

@import 'tailwindcss/base';

// // ==========================================================================||
// //  Custom Base
// // ==========================================================================||
@layer base {
}

// // ==========================================================================||
// //  Components
// // ==========================================================================||

@import 'tailwindcss/components';

// // ==========================================================================||
// //  Custom Components
// // ==========================================================================||
@layer components {
  // @import './elements/elements.content.scss';
  // @import './elements/elements.content-simple.scss';
  // @import './elements/elements.content-media-block.scss';
  // @import './elements/elements.content-list.scss';
  // @import './elements/elements.hamburgers.scss';

  // @import './objects/objects.wrapper.scss';
  // @import './objects/objects.grid.scss';

  // @import './components/components.carousel.scss';
  // @import './components/components.gallery-modal.scss';
  // @import './components/components.gallery-pagination.scss';
  // @import './components/components.events-swiper.scss';
  // @import './components/components.vue-formulate.scss';
  // @import './components/components.header-secondary.scss';
  // @import './components/components.card.scss';
  @import './components/components.plyr.scss';
}

// // ==========================================================================||
// //  Utilities
// // ==========================================================================||

@import 'tailwindcss/utilities';

// // ==========================================================================||
// //  Custom Utilities
// // ==========================================================================||
// @layer utilities {
//   // @import './utilities/_utilities.bg-colors.scss';
//   // @import './utilities/_utilities.typography.scss';
//   // @import './utilities/_utilities.misc.scss';
//   // @import './utilities/_utilities.list-style.scss';

// @import './utilities/_utilities.vue-animation.scss';
